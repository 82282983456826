import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useWatch } from 'react-hook-form';

import {
  FormTextField,
  FormLearnerInfoFields,
  FormAutocomplete,
  FormRadioGroup,
} from '~common/components';
import { dataLists } from '~common/utils';
import checkEmailExists from '@patheducation/admin/src/utils/checkEmail.js';
import { formatFieldName } from '@patheducation/login/src/components/registrationForm/helpers.js';
import useStyles from './styles';

const { countryCodeOptions } = dataLists;

// Email regex pattern
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-]+(\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const ParentLearnerFields = ({
  control,
  getValues,
  setValue,
  setError,
  clearErrors,
  watch,
  type,
  trigger,
}) => {
  const { classes } = useStyles();
  const isNewLearner = type === 'parentLearnerCreate';

  const [noContactDetails, setNoContactDetails] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  const email = useWatch({
    control,
    name: 'emailAddress',
  });

  const isValidEmail = (emailValue) => emailRegex.test(emailValue);

  const debouncedCheckEmail = useCallback(
    (() => {
      let timer;
      return (emailValue) => {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          if (isNewLearner && emailValue && isValidEmail(emailValue)) {
            try {
              const exists = await checkEmailExists(emailValue);
              setEmailExists(exists);
              if (exists) {
                setError('emailAddress', {
                  type: 'manual',
                  message: 'This email is already in use',
                });
              } else {
                clearErrors('emailAddress');
              }
            } catch (error) {
              console.error('Error checking email:', error);
              setError('emailAddress', {
                type: 'manual',
                message: 'Error checking email availability',
              });
            }
          } else if (isNewLearner && emailValue) {
            if (!isValidEmail(emailValue)) {
              setError('emailAddress', {
                type: 'manual',
                message: 'Please enter a valid email address',
              });
            } else {
              clearErrors('emailAddress');
            }
          } else {
            // If not a new learner or no contact details, clear any existing errors
            clearErrors('emailAddress');
            setEmailExists(false);
          }
        }, 500); // Increased debounce time for better UX
      };
    })(),
    [setError, clearErrors, isNewLearner],
  );

  useEffect(() => {
    if (!noContactDetails && isNewLearner) {
      debouncedCheckEmail(email);
    } else {
      // If no contact details or not a new learner, clear email-related errors and state
      clearErrors('emailAddress');
      setEmailExists(false);
    }
  }, [email, debouncedCheckEmail, noContactDetails, clearErrors, isNewLearner]);

  // If no contact details is checked, clear email and phone fields
  useEffect(() => {
    if (noContactDetails && isNewLearner) {
      setValue('emailAddress', '');
      setValue('phoneNumber', 0);
      setValue('noDetails', true);
      clearErrors('emailAddress');
      clearErrors('phoneNumber');
    }
  }, [noContactDetails, setValue, clearErrors, isNewLearner]);

  useEffect(() => {
    console.log('lol', getValues());
    if (!isNewLearner && getValues('noDetails') === true) {
      setNoContactDetails(true);
    }
  }, [isNewLearner, setValue]);

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="firstName"
          control={control}
          inputProps={{
            label: 'First Name',
            autoFocus: true,
            required: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="lastName"
          control={control}
          inputProps={{
            label: 'Last Name',
            required: true,
          }}
        />
      </Grid>

      {/* Conditionally render the "no contact details" checkbox only if isNewLearner is true */}
      {isNewLearner && (
        <Grid item xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={noContactDetails}
                onChange={(e) => setNoContactDetails(e.target.checked)}
                color="primary"
              />
            )}
            label="The learner does not have contact details"
          />
        </Grid>
      )}

      {/* Conditionally render email, phone, and ownAccount fields */}
      {/* If not a new learner, always show the fields */}
      {/* If new learner, show the fields only if noContactDetails is false */}
      {((!isNewLearner && !noContactDetails) || (isNewLearner && !noContactDetails)) && (
        <>
          <Grid item xs={12}>
            <FormTextField
              name="emailAddress"
              control={control}
              inputProps={{
                label: 'Email Address',
                required: isNewLearner,
                readOnly: !isNewLearner,
              }}
              error={emailExists}
              helperText={emailExists ? 'This email is already in use' : ''}
            />
            {!isNewLearner && (
              <Typography variant="body2" className={classes.message}>
                Please note: If you need to update your email address please contact an administrator.
              </Typography>
            )}
          </Grid>
          {isNewLearner && (
            <Grid item xs={12}>
              <FormRadioGroup
                name="ownAccount"
                control={control}
                label="Would you like the student to have access to their own account using their own log in details?"
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <FormAutocomplete
              name="phoneNumberPrefix"
              control={control}
              className={classes.prefix}
              options={countryCodeOptions}
              disableClearable
              getOptionLabel={(option) => (option ? option.value : '')}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  ({option.value}) {option.name}
                </li>
              )}
              defaultValue={getValues('phoneNumberPrefix')}
              inputProps={{
                placeholder: '+44',
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <FormTextField
              name="phoneNumber"
              control={control}
              inputProps={{
                label: 'Number',
                required: true,
              }}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <FormLearnerInfoFields
          type={type}
          control={control}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          trigger={trigger}
        />
      </Grid>
    </Grid>
  );
};

ParentLearnerFields.defaultProps = {
  getValues: null,
  setValue: null,
  clearErrors: () => {},
  setError: () => {},
  trigger: null,
};

ParentLearnerFields.propTypes = {
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  setError: PropTypes.func,
  trigger: PropTypes.func,
  watch: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['parentLearnerCreate', 'parentLearnerEdit']).isRequired,
};

export default ParentLearnerFields;
