// PaymentsSummary.jsx
import {
  Card,
  Grid,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import Panel from '../panel';

const SummaryTitleBar = ({ isParentUserType }) => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.summaryTitleBar}>
      {isParentUserType ? (
        <>
          <Grid item xs={6} sm={3}>
            <Typography variant="body1" className={classes.cardTitle}>
              Lesson Date
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography variant="body1" className={classes.cardTitle}>
              Time
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography variant="body1" className={classes.cardTitle}>
              Amount
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="body1" className={classes.cardTitle}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography variant="body1" className={classes.cardTitle}>
              Date Paid
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={3} sm={3}>
            <Typography variant="body1" className={classes.cardTitle}>
              Date
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography variant="body1" className={classes.cardTitle}>
              Amount
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" className={classes.cardTitle}>
              Total Hours
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Typography variant="body1" className={classes.cardTitle}>
              Status
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

SummaryTitleBar.propTypes = {
  isParentUserType: PropTypes.bool.isRequired,
};

const SummaryCard = ({
  date,
  amount,
  hours,
  status,
  id,
  url,
  isParentUserType,
  paidDate,
  time,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  // Convert pence to pounds.
  const amountValue = amount ? `£${(amount / 100).toFixed(2)}` : '£0.00';

  // Determine the message based on status
  const message = isParentUserType && status.toLowerCase() !== 'paid' ? 'Review and Pay' : 'Review';

  // Handle the click for the review link
  const handleReviewClick = (e) => {
    e.stopPropagation(); // Prevent card click
    if (isParentUserType && status.toLowerCase() !== 'paid' && url) {
      window.open(url, '_blank');
    } else {
      navigate(id);
    }
  };

  return (
    <Card className={classes.card}>
      <div className={classes.cardArea}>
        <Grid container>
          {isParentUserType ? (
            <>
              {/* Lesson Date */}
              <Grid item xs={6} sm={3} className={classes.cardItem}>
                {/* Field Title - Visible only on mobile */}
                <Typography
                  variant="body2"
                  className={classes.cardTitle}
                  display={{ xs: 'block', sm: 'none' }}
                >
                  Lesson Date
                </Typography>
                <Typography variant="body1" className={classes.cardText}>
                  {dayjs(date).format('DD MMMM YYYY')}
                </Typography>
              </Grid>
              {/* Time */}
              <Grid item xs={6} sm={2} className={classes.cardItem}>
                {/* Field Title - Visible only on mobile */}
                <Typography
                  variant="body2"
                  className={classes.cardTitle}
                  display={{ xs: 'block', sm: 'none' }}
                >
                  Time
                </Typography>
                <Typography variant="body1" className={classes.cardText}>
                  {time}
                </Typography>
              </Grid>
              {/* Amount */}
              <Grid item xs={6} sm={2} className={classes.cardItem}>
                {/* Field Title - Visible only on mobile */}
                <Typography
                  variant="body2"
                  className={classes.cardTitle}
                  display={{ xs: 'block', sm: 'none' }}
                >
                  Amount
                </Typography>
                <Typography variant="body1" className={classes.cardText}>
                  {amountValue}
                </Typography>
              </Grid>
              {/* Status */}
              <Grid item xs={6} sm={3} className={classes.cardItem}>
                {/* Field Title - Visible only on mobile */}
                <Typography
                  variant="body2"
                  className={classes.cardTitle}
                  display={{ xs: 'block', sm: 'none' }}
                >
                  Status
                </Typography>
                <Typography variant="body1" className={classes.cardText}>
                  {status}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.link}
                  onClick={handleReviewClick}
                >
                  {message}
                </Typography>
              </Grid>
              {/* Date Paid */}
              <Grid item xs={6} sm={2} className={classes.cardItem}>
                {/* Field Title - Visible only on mobile */}
                <Typography
                  variant="body2"
                  className={classes.cardTitle}
                  display={{ xs: 'block', sm: 'none' }}
                >
                  Date Paid
                </Typography>
                <Typography variant="body1" className={classes.cardText}>
                  {dayjs(paidDate).format('DD MMM YYYY')}
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              {/* Date */}
              <Grid item xs={3} sm={3} className={classes.cardItem}>
                {/* Field Title - Visible only on mobile */}
                <Typography
                  variant="body2"
                  className={classes.cardTitle}
                  display={{ xs: 'block', sm: 'none' }}
                >
                  Date
                </Typography>
                <Typography variant="body1" className={classes.cardText}>
                  {dayjs(date).format('DD MMMM YYYY')}
                </Typography>
              </Grid>
              {/* Amount */}
              <Grid item xs={3} sm={3} className={classes.cardItem}>
                {/* Field Title - Visible only on mobile */}
                <Typography
                  variant="body2"
                  className={classes.cardTitle}
                  display={{ xs: 'block', sm: 'none' }}
                >
                  Amount
                </Typography>
                <Typography variant="body1" className={classes.cardText}>
                  {amountValue}
                </Typography>
              </Grid>
              {/* Total Hours */}
              <Grid item xs={2} className={classes.cardItem}>
                {/* Field Title - Visible only on mobile */}
                <Typography
                  variant="body2"
                  className={classes.cardTitle}
                  display={{ xs: 'block', sm: 'none' }}
                >
                  Total Hours
                </Typography>
                <Typography variant="body1" className={classes.cardText}>
                  {hours}
                </Typography>
              </Grid>
              {/* Status */}
              <Grid item xs={4} sm={3} className={classes.cardItem}>
                {/* Field Title - Visible only on mobile */}
                <Typography
                  variant="body2"
                  className={classes.cardTitle}
                  display={{ xs: 'block', sm: 'none' }}
                >
                  Status
                </Typography>
                <Typography variant="body1" className={classes.cardText}>
                  {status}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.link}
                  onClick={handleReviewClick}
                >
                  {message}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </Card>
  );
};

SummaryCard.defaultProps = {
  url: null,
  time: 'N/A',
};

SummaryCard.propTypes = {
  date: PropTypes.string.isRequired,
  paidDate: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  url: PropTypes.string,
  isParentUserType: PropTypes.bool.isRequired,
  time: PropTypes.string,
};

const PaymentsSummary = ({
  invoices,
  totalEarned,
  totalHoursTutored,
  isParentUserType,
}) => {
  const { classes } = useStyles();
  // Convert pence to pounds.
  const totalEarnedAmount = totalEarned ? `£${(totalEarned / 100).toFixed(2)}` : '£0.00';

  const sortedInvoices = invoices?.slice().sort((a, b) => new Date(b.date) - new Date(a.date));

  const computeDateString = (lines) => {
    if (!lines || lines.length === 0) {
      return '';
    }

    if (lines.length === 1) {
      // Extract date from description
      const { description } = lines[0];
      // Get the date part before ', '
      const dateStr = description.split(', ')[0]; // e.g., "31/07/2023"
      // Parse dateStr
      const [day, month, year] = dateStr.split('/');
      // Create date object
      const dateObj = dayjs(`${year}-${month}-${day}`);
      // Format date
      const formattedDate = dateObj.format('DD MMMM YYYY');
      return formattedDate;
    }
    // Multiple lines
    // Get dates from lines
    const dates = lines.map((line) => dayjs(line.date));

    // Check if all dates are on the same day
    const firstDate = dates[0];
    const allSameDay = dates.every((date) => date.isSame(firstDate, 'day'));

    if (allSameDay) {
      // Return the date in desired format
      return firstDate.format('DD MMMM YYYY');
    }

    // Check if all dates are in the same month
    const allSameMonth = dates.every((date) => date.isSame(firstDate, 'month'));

    if (allSameMonth) {
      // Return the month in desired format
      return firstDate.format('MMMM YYYY');
    }

    // Multiple months
    const months = [...new Set(dates.map((date) => date.format('MMMM YYYY')))];
    return months.join(', ');
  };

  const computeTime = (lines) => {
    if (!lines || lines.length === 0) {
      return 'N/A';
    }

    if (lines.length === 1) {
      const { description } = lines[0];
      const timeRange = description.split(', ')[1]; // e.g., "11:00 - 12:00 UK / Accounting with Viktoriia Tutor21 for Viktoriia45 Petrova"
      // Extract only the time range before any additional info
      const cleanTimeRange = timeRange ? timeRange.split(' UK /')[0].trim() : 'N/A';
      return cleanTimeRange || 'N/A';
    }

    return 'N/A';
  };

  return (
    <>
      {!isParentUserType && (
        <Grid container spacing={3} className={classes.totals}>
          <Grid item xs={12} sm={6}>
            <Panel title="Total Paid" value={totalEarnedAmount} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Panel title="Total Hours Tutored" value={totalHoursTutored} />
          </Grid>
        </Grid>
      )}
      <div className={classes.cardList}>
        <SummaryTitleBar isParentUserType={isParentUserType} />
        {invoices?.length ? (
          sortedInvoices
            ?.filter((invoice) => invoice?.status !== 'void')
            ?.map((invoice) => {
              const dateString = isParentUserType
                ? computeDateString(invoice.lines)
                : dayjs(invoice?.updatedAt).format('DD MMMM YYYY');
              const timeString = isParentUserType
                ? computeTime(invoice.lines)
                : '';

              return (
                <SummaryCard
                  date={dateString}
                  amount={invoice?.total}
                  paidDate={isParentUserType ? invoice?.date : invoice?.updatedAt}
                  hours={invoice?.metadata?.totalHoursTutored}
                  status={`${invoice?.status.charAt(0).toUpperCase()}${invoice?.status.slice(1)}`}
                  id={invoice?.id}
                  url={invoice?.url}
                  key={invoice?.id}
                  isParentUserType={isParentUserType}
                  time={invoice.lines.length > 1 ? 'N/A' : timeString}
                />
              );
            })
        ) : (
          <Typography variant="body1" className={classes.noDataText}>
            No payment data.
          </Typography>
        )}
      </div>
    </>
  );
};

PaymentsSummary.defaultProps = {
  invoices: null,
  totalEarned: 0,
  totalHoursTutored: 0,
};

PaymentsSummary.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      total: PropTypes.number,
      lines: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          amount: PropTypes.number,
          status: PropTypes.string,
          date: PropTypes.string,
          description: PropTypes.string,
        }),
      ),
      metadata: PropTypes.shape({
        totalHoursTutored: PropTypes.number,
      }),
      status: PropTypes.string,
      date: PropTypes.string,
      id: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  totalEarned: PropTypes.number,
  totalHoursTutored: PropTypes.number,
  isParentUserType: PropTypes.bool.isRequired,
};

export default PaymentsSummary;
